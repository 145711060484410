import React from 'react';
import { FaCompressArrowsAlt, FaExpandArrowsAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';

const FullscreenButton = ({ isFullScreen, onToggleFullScreen }) => {
  return (
    <button onClick={onToggleFullScreen} aria-label={isFullScreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}>
      {isFullScreen ? <FaCompressArrowsAlt /> : <FaExpandArrowsAlt />}
    </button>
  );
};

FullscreenButton.propTypes = {
  isFullScreen: PropTypes.bool.isRequired,
  onToggleFullScreen: PropTypes.func.isRequired,
};

export default FullscreenButton;
