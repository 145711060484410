// src/components/Feed.js
import React, { useState, useEffect, useCallback } from 'react';
import Post from './Post';
import CreatePost from './CreatePost';
import Ads from './Ads'; // Import Ads component
import { api } from '../services/apiService'; // Use the authenticated Axios instance
import './Feed.css';
import debounce from 'lodash.debounce';
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode

// Import the SEO component and seoConfig
import SEO from './SEO';
import seoConfig from './seoConfig';

const Feed = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [feedType, setFeedType] = useState('discover'); // State to switch between discover and following feeds

  

  // Check if user is logged in based on the access token in localStorage
  useEffect(() => {
    const checkLoginStatus = () => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          if (decodedToken.exp && decodedToken.exp > currentTime) {
            setIsLoggedIn(true);
          } else {
            setIsLoggedIn(false);
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();
  }, []);

  // Function to load posts from the API
  const loadPosts = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    setError(null);

    try {
      // Load posts based on the selected feed type (discover or following)
      const endpoint = feedType === 'discover' ? `/feed/discover` : `/feed/following`;
      const response = await api.get(`${endpoint}?page=${page}`); // Use `api` instead of `publicApi`

      if (response.status !== 200) {
        throw new Error('Failed to fetch posts');
      }
      const newPosts = response.data;

      setPosts((prevPosts) => {
        // Filter out duplicate posts using unique post IDs
        const postIds = new Set(prevPosts.map((post) => post._id));
        const uniqueNewPosts = newPosts.filter((post) => !postIds.has(post._id));
        return [...prevPosts, ...uniqueNewPosts];
      });

      // Check if new posts were fetched and update hasMore
      if (newPosts.length === 0) {
        setHasMore(false); // No more posts available
      } else {
        setPage((prevPage) => prevPage + 1); // Increment page number for next fetch
      }
    } catch (error) {
      console.error('Error loading posts:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, page, feedType]);

  // Initial loading of posts
  useEffect(() => {
    loadPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedType]);

  // Debounced scroll handler
  useEffect(() => {
    const handleScroll = debounce(() => {
      // Check if the user has scrolled close to the bottom of the page
      const scrollTop = document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const scrollHeight = document.documentElement.scrollHeight;

      if (scrollHeight - (scrollTop + windowHeight) < 150 && hasMore && !loading) {
        loadPosts();
      }
    }, 300); // Debounce to limit the rate of requests

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadPosts, hasMore, loading]);

  


  return (
    <div className="feed">
      {/* Add SEO component for the homepage */}
      <SEO 
        title={seoConfig.home.title} 
        description={seoConfig.home.description} 
      />

      {isLoggedIn && (
        <div className="create-post-container">
          <CreatePost /> {/* Render the CreatePost component if logged in */}
        </div>
      )}

      <div className="feed-toggle">
        <button 
          onClick={() => { 
            setFeedType('discover'); 
            setPage(1); 
            setPosts([]); 
            setHasMore(true); 
          }}
        >
          Discover Feed
        </button>
        <button 
          onClick={() => { 
            setFeedType('following'); 
            setPage(1); 
            setPosts([]); 
            setHasMore(true); 
          }}
        >
          Following Feed
        </button>
      </div>

      {posts.map((post, index) => (
        <React.Fragment key={post._id}>
          <Post post={post} />
          
          {/* Insert ads after every 5th post */}
          {(index + 1) % 5 === 0 && (
            <div className="ad-container" key={`ad-${index}`}>
              {/* Outstream Video Ad */}
              <Ads
                type="outstream"
                spot="cd4c9882502440e7a0992e3ac3bcc30f"
                containerId={`ts_ad_outstream_${index}`}
                cookieExpires="4"
              />

              {/* Banner Ad */}
              <Ads
                type="banner"
                spot="243e1d1d1ab846cbba3e07378d31f65b"
                width={300}
                height={250}
                extid={`feed-${index}`}
              />

            </div>
          )}
        </React.Fragment>
      ))}

      {loading && <div>Loading...</div>}
      {error && <div className="error-message">{error}</div>}
      {!hasMore && <div>No more posts to load.</div>}
    </div>
  );
};

export default Feed;
