import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ProgressBar.css'; // Import the ProgressBar CSS

function formatTime(timeInSeconds) {
  if (!timeInSeconds || isNaN(timeInSeconds)) return '0:00';
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60).toString().padStart(2, '0');
  return `${minutes}:${seconds}`;
}

const ProgressBar = ({
  videoRef,
  currentTime,
  duration,
  onProgressChange
}) => {
  const progressRef = useRef(null); // Ref now points to the progress bar input
  const hiddenVideoRef = useRef(null);
  const canvasRef = useRef(null);
  const [hoverTime, setHoverTime] = useState(null);
  const [hoverPosition, setHoverPosition] = useState(null);
  const [thumbnailSrc, setThumbnailSrc] = useState(null);
  const thumbnailCache = useRef({});

  // Convert currentTime and duration strings ("M:SS") to seconds
  const timeToSeconds = (timeStr) => {
    const parts = timeStr.split(':').map(Number);
    if (parts.length === 2) {
      return parts[0] * 60 + parts[1];
    }
    return 0;
  };

  const currentTimeSeconds = timeToSeconds(currentTime);
  const durationSeconds = timeToSeconds(duration);

  // Calculate the percentage for the progress bar
  const progressPercent = durationSeconds > 0 ? (currentTimeSeconds / durationSeconds) * 100 : 0;

  const handleProgressChangeLocal = (e) => {
    if (!videoRef.current) return;
    const newPercent = parseFloat(e.target.value);
    const newTime = (newPercent / 100) * videoRef.current.duration;
    onProgressChange(newTime);
  };

  const handleProgressMouseMove = async (e) => {
    const progressBar = progressRef.current;
    const video = videoRef.current;
    const hiddenVideo = hiddenVideoRef.current;
    const canvas = canvasRef.current;

    if (!progressBar || !video || !hiddenVideo || !canvas) return;

    const rect = progressBar.getBoundingClientRect();
    let percent = (e.clientX - rect.left) / rect.width;
    percent = Math.max(0, Math.min(1, percent)); // Clamp between 0 and 1
    const time = percent * video.duration;
    setHoverTime(formatTime(time));

    // Calculate hover position relative to the progress bar
    const hoverPos = percent * rect.width;
    setHoverPosition(hoverPos);

    // Check if thumbnail for this time already exists
    const roundedTime = Math.floor(time);
    if (thumbnailCache.current[roundedTime]) {
      setThumbnailSrc(thumbnailCache.current[roundedTime]);
      return;
    }

    try {
      // Set the hidden video to the desired time
      hiddenVideo.currentTime = time;

      // Wait for the 'seeked' event
      hiddenVideo.onseeked = () => {
        // Draw the frame to the canvas
        canvas.width = hiddenVideo.videoWidth;
        canvas.height = hiddenVideo.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(hiddenVideo, 0, 0, canvas.width, canvas.height);

        // Generate the thumbnail image
        const dataURL = canvas.toDataURL('image/png');
        thumbnailCache.current[roundedTime] = dataURL; // Cache it
        setThumbnailSrc(dataURL);
      };
    } catch (error) {
      console.error('Error generating thumbnail:', error);
    }
  };

  const handleProgressMouseLeave = () => {
    setHoverTime(null);
    setHoverPosition(null);
    setThumbnailSrc(null);
  };

  return (
    <div className="progress-container">
      <input
        type="range"
        min="0"
        max="100"
        value={progressPercent}
        onChange={handleProgressChangeLocal}
        className="progress-bar"
        aria-label="Progress Bar"
        ref={progressRef}
        onMouseMove={handleProgressMouseMove}      // Moved event handler
        onMouseLeave={handleProgressMouseLeave}    // Moved event handler
      />
      {hoverTime && thumbnailSrc && (
        <div
          className="thumbnail-preview"
          style={{
            left: hoverPosition, // Position based on hover
          }}
        >
          <img
            src={thumbnailSrc}
            alt="Thumbnail"
          />
          <div className="time-indicator">
            {hoverTime}
          </div>
        </div>
      )}
      <div className="timestamp">
        {currentTime} / {duration}
      </div>

      {/* Hidden video element for capturing thumbnails */}
      <video
        ref={hiddenVideoRef}
        src={videoRef.current ? videoRef.current.src : ''}
        style={{ display: 'none' }}
        crossOrigin="anonymous"
      />

      {/* Canvas for drawing thumbnails */}
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

ProgressBar.propTypes = {
  videoRef: PropTypes.object.isRequired,
  currentTime: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  onProgressChange: PropTypes.func.isRequired,
};

export default ProgressBar;
