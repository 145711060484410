// src/components/Shorts.js
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { fetchShortsFeed } from '../services/apiService';
import './Shorts.css';
import LikeCommentShareButton from './LikeCommentShareButton';
import debounce from 'lodash.debounce';

// Import the SEO component and seoConfig
import SEO from './SEO'; // Adjust the path if SEO.js is in a different directory
import seoConfig from './seoConfig'; // Adjust the path based on your project structure

function Shorts() {
  const [shorts, setShorts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  // Reference to the container for attaching scroll event
  const containerRef = useRef(null);

  // Function to fetch shorts from the API
  const loadShorts = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    setError(null);

    try {
      const data = await fetchShortsFeed(page);

      setShorts((prevShorts) => {
        // Filter out duplicate posts using unique post IDs
        const postIds = new Set(prevShorts.map((short) => short._id));
        const uniqueNewShorts = data.filter((short) => !postIds.has(short._id));
        return [...prevShorts, ...uniqueNewShorts];
      });

      setHasMore(data.length > 0);
      if (data.length > 0) {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error('Error fetching shorts:', error);
      setError('Failed to load shorts. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, page]);

  useEffect(() => {
    loadShorts();
  }, [loadShorts]);

  // Debounced scroll handler to load more shorts
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (!containerRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 150 && hasMore && !loading) {
        loadShorts();
      }
    }, 300);

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loadShorts, hasMore, loading]);

  // Keep refs to each <video> element
  const videosRef = useRef([]);

  // Observe which video is currently in the viewport and auto-play it, pause others
  useEffect(() => {
    // Make sure the ref array is sized to the number of shorts
    videosRef.current = videosRef.current.slice(0, shorts.length);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const videoEl = entry.target;
          if (entry.isIntersecting) {
            // Attempt to play when in view
            videoEl.play().catch((err) => {
              // Some browsers will block auto-play if not muted, etc.
              console.error('Auto-play blocked or failed:', err);
            });
          } else {
            // Pause and optionally reset the video when out of view
            videoEl.pause();
            videoEl.currentTime = 0;
          }
        });
      },
      {
        // Adjust threshold so at least 50-70% of the video must be visible to play
        threshold: 0.6,
      }
    );

    // Observe all videos
    videosRef.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    // Cleanup
    return () => {
      videosRef.current.forEach((video) => {
        if (video) observer.unobserve(video);
      });
    };
  }, [shorts]);

  return (
    <div className="shorts-container" ref={containerRef}>
      {/* SEO */}
      <SEO title={seoConfig.shorts.title} description={seoConfig.shorts.description} />

      {/* Show loading on first load */}
      {loading && shorts.length === 0 && (
        <div className="shorts-container">Loading shorts...</div>
      )}

      {error && <div className="shorts-container error-message">{error}</div>}

      <h1 className="shorts-title">Shorts</h1>

      {shorts.length === 0 && !loading && !error ? (
        <p className="no-shorts-message">No shorts available at the moment.</p>
      ) : (
        <div className="shorts-grid">
          {shorts.map((short, index) => (
            <div className="short-item" key={short._id}>
              {short.mediaFiles && short.mediaFiles.length > 0 && (
                <video
                  ref={(el) => (videosRef.current[index] = el)}
                  src={short.mediaFiles[0]}
                  title={short.description || 'Untitled'}
                  className="short-video"
                  preload="metadata"
                  poster={short.thumbnail || undefined}
                  // Muted + playsInline can help with auto-play on mobile
                  muted
                  playsInline
                  // If you remove "controls", it will look more like TikTok
                  controls
                >
                  Your browser does not support the video tag.
                </video>
              )}
              <div className="short-info">
                <p className="short-caption">
                  {short.description || 'No description available.'}
                </p>
                <LikeCommentShareButton postId={short._id} />
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Loading more spinner */}
      {loading && page > 1 && <div className="loading-more">Loading more shorts...</div>}

      {/* No more shorts */}
      {!hasMore && <div className="no-more-shorts">No more shorts to load.</div>}
    </div>
  );
}

export default Shorts;
