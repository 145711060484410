// src/components/ProfilePage.js
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import CreatePost from './CreatePost';
import UserPosts from './UserPosts';
import { api } from '../services/apiService';
import './ProfilePage.css';

import SEO from './SEO';
import seoConfig from './seoConfig';

const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error('Failed to parse JWT:', e);
    return null;
  }
};

const ProfilePage = () => {
  const { username } = useParams();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [bioInput, setBioInput] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);

  // Fetch user information
  const fetchUserInfo = useCallback(async () => {
    try {
      const response = await api.get(`/users/${username}`);
      if (response.status === 200) {
        const data = response.data;
        setUserInfo(data);
        setBioInput(data.bio || '');
      } else {
        throw new Error('Failed to fetch user information');
      }
    } catch (err) {
      console.error('Error fetching user information:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [username]);

  // Fetch logged-in user information from JWT
  const fetchLoggedInUser = useCallback(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      const decoded = parseJwt(token);
      if (decoded && decoded.userId) {
        setLoggedInUser(decoded.userId);
      } else {
        console.error('User ID not found in token');
        setLoggedInUser(null);
      }
    } else {
      setLoggedInUser(null);
    }
  }, []);

  // Handle follow action
  const handleFollow = async () => {
    try {
      const response = await api.post(
        `/follow/follow/${username}`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      );
      if (response.status === 200 && userInfo) {
        setUserInfo({ ...userInfo, followers: [...userInfo.followers, loggedInUser] });
      } else {
        console.error('Failed to follow user');
        setError('Failed to follow user. Please try again.');
      }
    } catch (err) {
      console.error('Error following user:', err);
      setError('Failed to follow user. Please try again.');
    }
  };

  // Handle unfollow action
  const handleUnfollow = async () => {
    try {
      const response = await api.post(
        `/follow/unfollow/${username}`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      );
      if (response.status === 200 && userInfo) {
        setUserInfo({ ...userInfo, followers: userInfo.followers.filter(id => id !== loggedInUser) });
      } else {
        console.error('Failed to unfollow user');
        setError('Failed to unfollow user. Please try again.');
      }
    } catch (err) {
      console.error('Error unfollowing user:', err);
      setError('Failed to unfollow user. Please try again.');
    }
  };

  // Handle profile update
  const handleProfileUpdate = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('bio', bioInput);
    if (profileImage) {
      formData.append('profileImage', profileImage);
    }
    try {
      const response = await api.put('/users/update-profile', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.status === 200) {
        const data = response.data;
        setUserInfo({ ...userInfo, bio: data.bio, profileImage: data.profileImage });
        alert('Profile updated successfully');
        setIsEditing(false);
      } else {
        throw new Error('Failed to update profile');
      }
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Failed to update profile. Please try again.');
    }
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    fetchUserInfo();
    fetchLoggedInUser();
  }, [fetchUserInfo, fetchLoggedInUser]);

  const seoData = seoConfig.profile(username);

  return (
    <div className="profile-page">
      <SEO title={seoData.title} description={seoData.description} />

      {loading && !error && <div className="profile-loading">Loading...</div>}
      {error && <div className="profile-error"><p>Error: {error}</p></div>}

      {!loading && !error && userInfo && (
        <div className="profile-info">
          <h1>{userInfo.username}'s Profile</h1>
          <img
            src={userInfo.profileImage || `${process.env.PUBLIC_URL}/default-profile.png`}
            alt="Profile"
            className="profile-image"
          />
          {!isEditing ? (
            <p>{userInfo.bio || 'This user has not added a bio yet.'}</p>
          ) : (
            <form onSubmit={handleProfileUpdate} className="profile-edit-form">
              <textarea
                value={bioInput}
                onChange={(e) => setBioInput(e.target.value)}
                placeholder="Update your bio"
                className="bio-edit-input"
              />
              <input
                type="file"
                accept="image/*"
                onChange={(e) => setProfileImage(e.target.files[0])}
                className="profile-image-input"
              />
              <button type="submit" className="save-profile-btn">Save Changes</button>
              <button type="button" onClick={toggleEditMode} className="cancel-profile-btn">Cancel</button>
            </form>
          )}
          <p>Followers: {userInfo.followers.length}</p>
          {loggedInUser && loggedInUser !== userInfo._id && (
            userInfo.followers.includes(loggedInUser) ? (
              <button onClick={handleUnfollow} className="unfollow-button">Unfollow</button>
            ) : (
              <button onClick={handleFollow} className="follow-button">Follow</button>
            )
          )}
          {loggedInUser && loggedInUser === userInfo._id && (
            <div className="profile-edit">
              {!isEditing && (
                <button onClick={toggleEditMode} className="edit-profile-btn">Edit Profile</button>
              )}
              <CreatePost />
            </div>
          )}
        </div>
      )}

      {/* Just render UserPosts here, no posts passed as props. 
          UserPosts will handle fetching posts by username */}
      {!loading && !error && userInfo && (
        <UserPosts />
      )}
    </div>
  );
};

export default ProfilePage;
