import React from 'react';
import PropTypes from 'prop-types';

const SettingsMenu = ({
  showSettings,
  playbackRate,
  onPlaybackRateChange,
  qualityOptions,
  currentQuality,
  onQualityChange,
}) => {
  if (!showSettings) return null;

  return (
    <div className="settings-menu">
      <label>
        Speed:
        <select value={playbackRate} onChange={onPlaybackRateChange} aria-label="Playback Speed">
          {[0.25, 0.5, 1, 1.25, 1.5, 1.75, 2].map((rate) => (
            <option key={rate} value={rate}>
              {rate}x
            </option>
          ))}
        </select>
      </label>

      {qualityOptions.length > 0 && (
        <label>
          Quality:
          <select
            value={currentQuality?.label || ''}
            onChange={onQualityChange}
            aria-label="Quality Selection"
          >
            {qualityOptions.map((option, index) => (
              <option key={index} value={option.label}>
                {option.label}
              </option>
            ))}
          </select>
        </label>
      )}
    </div>
  );
};

SettingsMenu.propTypes = {
  showSettings: PropTypes.bool.isRequired,
  playbackRate: PropTypes.number.isRequired,
  onPlaybackRateChange: PropTypes.func.isRequired,
  qualityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    })
  ),
  currentQuality: PropTypes.shape({
    label: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  }),
  onQualityChange: PropTypes.func.isRequired,
};

SettingsMenu.defaultProps = {
  qualityOptions: [],
  currentQuality: null,
};

export default SettingsMenu;
