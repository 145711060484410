import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { api } from '../services/apiService';
import './UserPosts.css';
import VideoThumbnails from './VideoThumbnails';

// A tiny IntersectionObserver-based lazy loader for videos
// The <video> won't set its <source> until in viewport.
const LazyVideo = ({ src, className, ...props }) => {
  const videoRef = useRef(null);
  const [shouldLoad, setShouldLoad] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting) {
          setShouldLoad(true);
          observer.unobserve(firstEntry.target); // Stop observing once loaded
        }
      },
      {
        threshold: 0.1, // Adjust as needed (0 to 1)
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <video ref={videoRef} className={className} {...props}>
      {shouldLoad && <source src={src} type="video/mp4" />}
      {/* Fallback text if needed */}
      Your browser does not support the video tag.
    </video>
  );
};

const UserPosts = () => {
  const { username } = useParams();

  const [userId, setUserId] = useState(null);
  const [userPosts, setUserPosts] = useState([]);

  // Pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Loading & Error states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Sorting & Filtering
  const [sortOption, setSortOption] = useState('latest'); // "latest" | "popular"
  const [filterOption, setFilterOption] = useState('all'); // "all" | "short" | "image" | "video"

  // Intersection Observer sentinel
  const sentinelRef = useRef(null);

  /************************************************************
   * 1. Fetch userId by username
   ************************************************************/
  const fetchUserId = useCallback(async () => {
    if (!username) return;

    setLoading(true);
    try {
      const response = await api.get(`/users/${username}`);
      if (response.status === 200 && response.data?._id) {
        setUserId(response.data._id);
        setError(null);
      } else {
        setError('User not found.');
      }
    } catch (err) {
      console.error('Error fetching user by username:', err);
      setError('Failed to fetch user information.');
    } finally {
      setLoading(false);
    }
  }, [username]);

  /************************************************************
   * 2. Load 10 posts at a time (supports sort & filter)
   ************************************************************/
  const loadMorePosts = useCallback(async () => {
    if (!userId || loading || page > totalPages) return;

    setLoading(true);
    try {
      // Include sort & filter in the query params
      const response = await api.get(
        `/posts/user/${userId}?page=${page}&limit=10&sort=${sortOption}&filter=${filterOption}`
      );

      if (response.status === 200) {
        const data = response.data;
        setUserPosts((prev) => [...prev, ...data.posts]);
        setTotalPages(data.totalPages);
        setPage((prevPage) => prevPage + 1);
        setError(null);
      } else {
        setError('Failed to fetch posts.');
      }
    } catch (err) {
      console.error('Error loading more posts:', err);
      setError('Failed to fetch posts.');
    } finally {
      setLoading(false);
    }
  }, [userId, loading, page, totalPages, sortOption, filterOption]);

  /************************************************************
   * 3. Intersection Observer for infinite scrolling
   ************************************************************/
  useEffect(() => {
    if (loading || page > totalPages) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting) {
          loadMorePosts();
        }
      },
      {
        root: null,
        rootMargin: '200px',
        threshold: 0,
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [loadMorePosts, loading, page, totalPages]);

  /************************************************************
   * 4. On mount (or username change), reset & fetch user
   ************************************************************/
  useEffect(() => {
    setUserId(null);
    setUserPosts([]);
    setPage(1);
    setTotalPages(1);
    setError(null);

    if (username) {
      fetchUserId();
    }
  }, [username, fetchUserId]);

  /************************************************************
   * 5. When userId is set, fetch first page
   ************************************************************/
  useEffect(() => {
    if (userId) {
      loadMorePosts();
    }
  }, [userId, loadMorePosts]);

  /************************************************************
   * 6. When sort/filter changes, reset & fetch again
   ************************************************************/
  useEffect(() => {
    // If userId is not set yet, no need to re-fetch
    if (!userId) return;

    // Reset and fetch from page 1 with new sort/filter
    setUserPosts([]);
    setPage(1);
    setTotalPages(1);

    // loadMorePosts will be called below
  }, [sortOption, filterOption, userId]);

  // This effect triggers loadMorePosts after we reset the page above
  useEffect(() => {
    if (userId && page === 1 && !loading) {
      loadMorePosts();
    }
  }, [userId, page, loading, loadMorePosts]);

  /************************************************************
   * 7. Handlers for sort & filter changes
   ************************************************************/
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilterOption(e.target.value);
  };

  /************************************************************
   * 8. Render
   ************************************************************/
  if (error) {
    return <p>{error}</p>;
  }

  if (!userId && !loading) {
    return <p>User not found or loading...</p>;
  }

  return (
    <div className="user-posts-container">
      {/* === Sort & Filter Controls === */}
      <div className="sort-filter-bar">
        <label>
          Sort by:&nbsp;
          <select value={sortOption} onChange={handleSortChange}>
            <option value="latest">Latest</option>
            <option value="popular">Most Viewed</option>
          </select>
        </label>

        <label>
          Filter:&nbsp;
          <select value={filterOption} onChange={handleFilterChange}>
            <option value="all">All</option>
            <option value="short">Shorts only</option>
            <option value="image">Photos only</option>
            <option value="video">Videos only</option>
          </select>
        </label>
      </div>

      {/* === Posts Grid === */}
      <div className="posts-grid">
        {userPosts.length > 0 ? (
          userPosts.map((post) => (
            <Link
              key={post._id}
              to={`/post/${post._id}`}
              className="post-link"
              aria-label={`View post ${post.description || 'No description'}`}
            >
              <div className="post">
                <p className="post-description">{post.description}</p>

                {/* Render each media item */}
                {post.mediaFiles?.map((file, index) => {
                  const type = post.mediaTypes[index];
                  const thumbnailUrl =
                    post.thumbnails && post.thumbnails[index]
                      ? post.thumbnails[index]
                      : null;

                  // === IMAGES ===
                  if (type === 'image') {
                    return (
                      <div key={index} className="media-container">
                        <img
                          loading="lazy"
                          src={file}
                          alt="Post media"
                          className="post-media"
                        />
                      </div>
                    );
                  }

                  // === REGULAR VIDEOS (with thumbnail logic) ===
                  else if (type === 'video') {
                    const videoData = {
                      _id: post._id,
                      description: post.description,
                      mediaTypes: [type],
                      mediaFiles: [file],
                      thumbnails: thumbnailUrl ? [thumbnailUrl] : [],
                    };
                    // VideoThumbnails is presumably your existing solution
                    // If you want to lazy-load the actual video:
                    // 1) Use <LazyVideo /> in that component
                    // 2) Or dynamically load the <video> there
                    return (
                      <div key={index} className="media-item">
                        <VideoThumbnails video={videoData} onClick={() => {}} />
                        <div className="play-indicator" aria-hidden="true">
                          ▶
                        </div>
                      </div>
                    );
                  }

                  // === SHORT VIDEOS ===
                  else if (type === 'short') {
                    // Use LazyVideo to load only when visible
                    return (
                      <div key={index} className="media-container">
                        <LazyVideo
                          className="post-media short-video"
                          autoPlay
                          muted
                          loop
                          playsInline
                          src={file}
                        />
                      </div>
                    );
                  }

                  // Unrecognized media type
                  return null;
                })}
              </div>
            </Link>
          ))
        ) : (
          !loading && <p>No posts available</p>
        )}
      </div>

      {/* Loading Indicator */}
      {loading && <p>Loading more posts...</p>}

      {/* Sentinel at bottom */}
      <div ref={sentinelRef} style={{ height: '1px' }} />
    </div>
  );
};

export default UserPosts;
