// src/pages/ModQueuePage.js
import React, { useState, useEffect } from 'react';
import { api } from '../services/apiService';
import './ModQueuePage.css';

function ModQueuePage() {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Helper function to get the access token from localStorage
  const getAuthHeader = () => {
    const token = localStorage.getItem('accessToken');
    return token ? { Authorization: `Bearer ${token}` } : {};
  };

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await api.get('/mod/queue', {
          headers: getAuthHeader(),
        });
        if (response.status === 200) {
          setReports(response.data);
        } else {
          setError('Failed to fetch reports.');
        }
      } catch (error) {
        setError('Error fetching reports.');
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  const handleAction = async (reportId, action) => {
    try {
      const response = await api.post(
        '/mod/action',
        { reportId, action },
        {
          headers: {
            ...getAuthHeader(),
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200) {
        alert('Action performed successfully.');
        setReports(reports.filter((report) => report._id !== reportId));
      } else {
        setError('Failed to perform action.');
      }
    } catch (error) {
      setError('Error performing action.');
    }
  };

  if (loading) {
    return <div className="ModQueuePage">Loading...</div>;
  }

  if (error) {
    return <div className="ModQueuePage error-message">{error}</div>;
  }

  return (
    <div className="ModQueuePage">
      <h2>Moderator Queue</h2>
      {reports.length === 0 ? (
        <p>No pending reports at the moment.</p>
      ) : (
        <div className="reports-list">
          {reports.map((report) => (
            <div key={report._id} className="report-item">
              <h3>Post by: {report.reportedBy.username}</h3>
              <p>Description: {report.postId.description}</p>
              <div className="action-buttons">
                <button
                  onClick={() => handleAction(report._id, 'approve')}
                  className="approve-button"
                >
                  Approve
                </button>
                <button
                  onClick={() => handleAction(report._id, 'remove')}
                  className="remove-button"
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ModQueuePage;
