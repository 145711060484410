// src/components/PostPage.js
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import LikeCommentShareButton from './LikeCommentShareButton';
import VideoPlayer from './VideoPlayer';
import { api, publicApi } from '../services/apiService';
import './PostPage.css';
import SEO from './SEO';
import seoConfig from './seoConfig';
import Ads from './Ads'; // Central Ads component
import SimilarPosts from './SimilarPosts'; // <-- Import your SimilarPosts component

const PostPage = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Use your extid for banner ads or outstream ads
  const extid = 'YOUR_EXTID_VALUE';

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const [postResponse, commentsResponse] = await Promise.all([
          publicApi.get(`/posts/${id}`),
          publicApi.get(`/posts/${id}/comments`),
        ]);

        if (postResponse.status !== 200) throw new Error('Post not found');
        if (commentsResponse.status !== 200) throw new Error('Comments not found');

        const postData = postResponse.data;
        postData.description = DOMPurify.sanitize(postData.description);

        if (isMounted) {
          setPost(postData);
          setComments(commentsResponse.data);
        }

        if (postData.username) {
          try {
            const userResponse = await api.get(`/users/${postData.username}`);
            if (userResponse.status === 200 && isMounted) {
              setProfileImage(userResponse.data.profileImage);
            }
          } catch (userError) {
            console.error('Error fetching user data:', userError);
          }
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [id]);

  const handleCommentSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const sanitizedComment = DOMPurify.sanitize(newComment.trim());
      if (!sanitizedComment) return;

      try {
        const response = await api.post(`/posts/${id}/comments`, {
          text: sanitizedComment,
        });

        if (response.status === 201) {
          setComments((prevComments) => [...prevComments, response.data]);
          setNewComment('');
        } else {
          throw new Error('Failed to submit comment');
        }
      } catch (submitError) {
        console.error('Error submitting comment:', submitError);
        setError('Failed to submit comment. Please try again.');
      }
    },
    [id, newComment]
  );

  const seoData = useMemo(() => {
    return post
      ? seoConfig.post(post.username, post.description)
      : {
          title: 'Neon Desire - View Post',
          description: 'Explore exclusive content on Neon Desire.',
        };
  }, [post]);

  const renderedMedia = useMemo(() => {
    if (!post || !post.mediaFiles || !post.mediaTypes) return null;
    return post.mediaFiles.map((mediaUrl, index) => {
      const mediaType = post.mediaTypes[index];
      if (mediaType === 'image') {
        return (
          <img
            key={index}
            src={mediaUrl}
            alt="Post Media"
            className="post-image"
            loading="lazy"
          />
        );
      } else if (mediaType === 'video') {
        return (
          <VideoPlayer
            key={index}
            poster={post.poster}
            qualityOptions={[{ label: 'Default', src: mediaUrl }]}
          />
        );
      }
      return null;
    });
  }, [post]);

  const renderedShorts = useMemo(() => {
    if (!post || !post.mediaFiles || !post.mediaTypes) return null;
    return post.mediaFiles
      .map((mediaUrl, index) => {
        if (post.mediaTypes[index] === 'short') {
          return (
            <div key={index} className="short-content">
              <VideoPlayer
                poster={post.poster}
                qualityOptions={[{ label: 'Default', src: mediaUrl }]}
              />
            </div>
          );
        }
        return null;
      })
      .filter(Boolean);
  }, [post]);

  return (
    <div className="post-page-container">
      <SEO title={seoData.title} description={seoData.description} />

      {loading && !error && <div className="post-loading">Loading post...</div>}
      {error && <div className="post-error">Error: {error}</div>}

      {!loading && !error && post && (
        <div className="post-content">
          <div className="post-header">
            {profileImage && (
              <img
                src={profileImage}
                alt={`${post.username}'s profile`}
                className="post-picture"
                loading="lazy"
              />
            )}
            <h2 className="post-username">
              <Link to={`/profile/${post.username}`}>{post.username}</Link>
            </h2>
          </div>

          <p className="post-body">{post.description}</p>

          <div className="post-media-container">{renderedMedia}</div>

          {post.mediaTypes.includes('short') && (
            <div className="post-shorts-container">{renderedShorts}</div>
          )}

          <LikeCommentShareButton postId={id} />

          <h2 className="comments-header">Comments</h2>
          {comments.length > 0 ? (
            <ul className="comments-list">
              {comments.map((comment) => (
                <li key={comment._id} className="comment-item">
                  <strong>{comment.username}</strong>: {comment.text}
                </li>
              ))}
            </ul>
          ) : (
            <p className="no-comments">No comments yet.</p>
          )}

          <form className="comment-form" onSubmit={handleCommentSubmit}>
            <textarea
              className="comment-input"
              placeholder="Leave a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              required
            />
            <button type="submit" className="comment-submit-btn">
              Submit
            </button>
          </form>
        </div>
      )}

      {/* Ads at the bottom */}
      <div className="ad-section" style={{ marginTop: '50px' }}>
        {/* Row 1: Two banner ads */}
        <div className="ad-row">
          <Ads
            type="banner"
            spot="243e1d1d1ab846cbba3e07378d31f65b"
            width={300}
            height={250}
            extid={extid}
          />
          <Ads
            type="banner"
            spot="243e1d1d1ab846cbba3e07378d31f65b"
            width={300}
            height={250}
            extid={extid}
          />
        </div>

        {/* Row 2: Two outstream ads - wrapped in a fixed container */}
        <div className="ad-row">
          <div className="outstream-fixed">
            <Ads
              type="outstream"
              spot="cd4c9882502440e7a0992e3ac3bcc30f"
              containerId="ts_ad_video_g4p70"
              cookieExpires="4"
              width={300}
              height={250}
            />
          </div>
          <div className="outstream-fixed">
            <Ads
              type="outstream"
              spot="cd4c9882502440e7a0992e3ac3bcc30f"
              containerId="ts_ad_video_tzw2c"
              cookieExpires="4"
              width={300}
              height={250}
            />
          </div>
        </div>
      </div>

      {/* Finally, display similar posts at the bottom */}
      <div className="similar-posts-wrapper">
        <SimilarPosts postId={id} />
      </div>
    </div>
  );
};

export default PostPage;
