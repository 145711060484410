import React from 'react';
import PropTypes from 'prop-types';
import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa';

const VolumeControl = ({
  volume,
  isMuted,
  onMuteToggle,
  onVolumeChange,
  volumeSliderRef
}) => {
  return (
    <div className="volume-control-wrapper">
      <div className="volume-control">
        <button 
          onClick={onMuteToggle} 
          aria-label={isMuted || volume === 0 ? 'Unmute' : 'Mute'}
        >
          {isMuted || volume === 0 ? <FaVolumeMute /> : <FaVolumeUp />}
        </button>
        <input
          ref={volumeSliderRef}
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={onVolumeChange}
          className="volume-slider"
          aria-label="Volume Slider"
        />
      </div>
    </div>
  );
};

VolumeControl.propTypes = {
  volume: PropTypes.number.isRequired,
  isMuted: PropTypes.bool.isRequired,
  onMuteToggle: PropTypes.func.isRequired,
  onVolumeChange: PropTypes.func.isRequired,
  volumeSliderRef: PropTypes.object
};

export default VolumeControl;
