// src/components/VideoThumbnails.js

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './VideoThumbnails.css';

// Convert duration in seconds to mm:ss
function formatDuration(seconds = 0) {
  const totalSeconds = Math.floor(seconds);
  const minutes = Math.floor(totalSeconds / 60);
  const secs = totalSeconds % 60;
  return `${minutes}:${secs.toString().padStart(2, '0')}`;
}

function VideoThumbnails({ video, onClick }) {
  const [metadataDuration, setMetadataDuration] = useState(null);
  const fallbackVideoRef = useRef(null);
  const hiddenVideoRef = useRef(null);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick(video._id);
    }
  };

  const handleImageError = (e) => {
    if (e.target) {
      e.target.onerror = null; 
      e.target.src = '/path/to/default-thumbnail.png'; // Ensure this path is correct
    }
  };

  const displayedDuration =
    typeof video.duration === 'number' ? video.duration : metadataDuration;

  useEffect(() => {
    // If we already have a duration (from props or state), no need to do anything.
    // If there's no mediaFiles or it's not a video, we can't load anything.
    if (displayedDuration || !video.mediaFiles || video.mediaFiles.length === 0 || !video.mediaTypes || video.mediaTypes[0] !== 'video') {
      return;
    }

    // If no duration and there's a thumbnail, we need to load a hidden video to get the duration.
    // This hidden video only loads if we haven't got duration yet and if we have at least one media file.
    if (video.thumbnails && video.thumbnails.length > 0 && hiddenVideoRef.current) {
      // Set up metadata loading if needed.
      const hiddenVideo = hiddenVideoRef.current;
      const handleMetadata = () => {
        setMetadataDuration(hiddenVideo.duration);
      };
      hiddenVideo.addEventListener('loadedmetadata', handleMetadata);

      return () => {
        hiddenVideo.removeEventListener('loadedmetadata', handleMetadata);
      };
    }
  }, [displayedDuration, video]);

  return (
    <div
      className="video-item"
      onClick={() => onClick(video._id)}
      role="button"
      tabIndex={0}
      aria-label={`View video ${video.description || 'No description'}`}
      onKeyPress={handleKeyPress}
    >
      {video.mediaTypes && video.mediaTypes[0] === 'video' && (
        <div className="video-thumbnail-container">
          {video.thumbnails && video.thumbnails.length > 0 ? (
            <>
              {/* If a thumbnail is available, show it directly */}
              <img
                src={video.thumbnails[0]}
                alt={`Thumbnail for video ${video._id}`}
                className="video-thumbnail"
                loading="lazy"
                onError={handleImageError}
              />

              {/* If no duration provided and we haven't derived it yet, load hidden video to get metadata */}
              {!video.duration && !metadataDuration && video.mediaFiles && video.mediaFiles.length > 0 && (
                <video
                  ref={hiddenVideoRef}
                  src={video.mediaFiles[0]}
                  preload="metadata"
                  muted
                  playsInline
                  style={{ display: 'none', visibility: 'hidden', width: 0, height: 0 }}
                />
              )}
            </>
          ) : (
            // If no thumbnail is provided, use the fallback <video> to grab first frame and duration
            <video
              ref={fallbackVideoRef}
              src={video.mediaFiles[0]}
              className="video-thumbnail"
              preload="metadata"
              muted
              playsInline
              onLoadedMetadata={(e) => {
                try {
                  const duration = e.target.duration;
                  setMetadataDuration(duration);
                  // Seek a small offset to force the first frame to display
                  e.target.currentTime = 0.1;
                  e.target.pause();
                } catch (error) {
                  console.error('Error displaying video fallback thumbnail:', error);
                }
              }}
            />
          )}

          {typeof displayedDuration === 'number' && (
            <div className="video-duration-overlay">
              {formatDuration(displayedDuration)}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

VideoThumbnails.propTypes = {
  video: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    mediaTypes: PropTypes.arrayOf(PropTypes.string),
    thumbnails: PropTypes.arrayOf(PropTypes.string),
    mediaFiles: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    duration: PropTypes.number, // Duration in seconds (optional)
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default VideoThumbnails;
