// src/components/SimilarPosts.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchSimilarPosts } from '../services/apiService'; // Adjust if needed
import VideoThumbnails from './VideoThumbnails'; // Same video thumbnail logic
import './SimilarPosts.css';

/**
 * A reusable component to display similar posts on a post page.
 * Uses the same thumbnail logic for videos as Videos.js (via VideoThumbnails),
 * and displays the first image in mediaFiles for image-type posts.
 *
 * @param {string} postId - The ID of the post to find similar posts for.
 */
const SimilarPosts = ({ postId }) => {
  const [similarPosts, setSimilarPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!postId) return;

    const getSimilarPosts = async () => {
      setLoading(true);
      setError(null);

      try {
        const data = await fetchSimilarPosts(postId);
        setSimilarPosts(data);
      } catch (err) {
        console.error('Error fetching similar posts:', err);
        setError('Failed to load similar posts. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    getSimilarPosts();
  }, [postId]);

  if (loading) {
    return <p className="similar-posts__loading">Loading Similar Posts...</p>;
  }

  if (error) {
    return <div className="similar-posts__error-message">{error}</div>;
  }

  if (!similarPosts || similarPosts.length === 0) {
    return <p className="similar-posts__no-posts-message">No similar posts found.</p>;
  }

  return (
    <div className="similar-posts-feed">
      <h2 className="similar-posts-title">Similar Posts</h2>
      <div className="similar-posts-grid">
        {similarPosts.map((post) => {
          // We only display the *first* media item.
          const firstMediaType = post.mediaTypes?.[0];
          const firstMediaUrl = post.mediaFiles?.[0];

          return (
            <Link
              key={post._id}
              to={`/post/${post._id}`}
              className="similar-post-item"
              aria-label={`View post: ${post.description || 'No description'}`}
            >
              {firstMediaType === 'video' ? (
                // Uses the same thumbnail logic for videos as in Videos.js
                <VideoThumbnails video={post} />
              ) : (
                // Otherwise, show the first image (if it exists)
                <img
                  src={firstMediaUrl}
                  alt={post.description || 'No caption'}
                  className="similar-posts-image"
                />
              )}

              <div className="similar-posts-details">
                <p className="similar-posts-caption">
                  {post.description || 'No caption available.'}
                </p>
                <p className="similar-posts-views">
                  {post.viewCount !== undefined
                    ? `${post.viewCount} views`
                    : 'No views yet.'}
                </p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SimilarPosts;
