// PCQuickActions.js
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const PCQuickActions = ({
  containerRef,
  isMobile,
  handlePlayPause,
  handleSkip,
  handleFullScreenToggle
}) => {
  useEffect(() => {
    // Only run on non-mobile devices
    if (isMobile) return;
    const container = containerRef.current;
    if (!container) return;

    // Keydown events for PC
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowRight':
          handleSkip(10);
          break;
        case 'ArrowLeft':
          handleSkip(-10);
          break;
        case 'f':
        case 'F':
          // Toggle fullscreen with 'f'
          handleFullScreenToggle();
          break;
        case 'Escape':
          // Exit fullscreen with 'Escape' if currently in fullscreen
          if (document.fullscreenElement === container) {
            handleFullScreenToggle();
          }
          break;
        case ' ':
          // Spacebar toggles play/pause
          e.preventDefault(); // Prevent scrolling
          handlePlayPause();
          break;
        default:
          break;
      }
    };

    // Add keydown listener
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      // Clean up listener
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [containerRef, isMobile, handlePlayPause, handleSkip, handleFullScreenToggle]);

  return null;
};

PCQuickActions.propTypes = {
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  handlePlayPause: PropTypes.func.isRequired,
  handleSkip: PropTypes.func.isRequired,
  handleFullScreenToggle: PropTypes.func.isRequired,
};

export default PCQuickActions;
