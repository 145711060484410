// VideoPlayer.js
import React, { useRef, useState, useEffect, useCallback } from 'react';
import './VideoPlayer.css';
import { FaPlay, FaPause } from 'react-icons/fa';
import PropTypes from 'prop-types';

import SkipButton from './VideoPlayer/SkipButton';
import PiPButton from './VideoPlayer/PiPButton';
import FullscreenButton from './VideoPlayer/FullscreenButton';
import SettingsButton from './VideoPlayer/SettingsButton';
import SettingsMenu from './VideoPlayer/SettingsMenu';
import ProgressBar from './VideoPlayer/ProgressBar';
import VolumeControl from './VideoPlayer/VolumeControl';
import GestureControlsMobile from './VideoPlayer/GestureControlsMobile';
import PCQuickActions from './VideoPlayer/PCQuickActions';

/**
 * Helper to format time from seconds to mm:ss
 */
function formatTime(timeInSeconds) {
  if (!timeInSeconds || isNaN(timeInSeconds)) return '0:00';
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60)
    .toString()
    .padStart(2, '0');
  return `${minutes}:${seconds}`;
}

/**
 * Custom hook that automatically hides and shows controls based on mouse/touch inactivity.
 * Controls are shown immediately on any user interaction (mouse move or touch),
 * and hidden after 5 seconds of no interaction IF the video is playing or in fullscreen.
 */
function useAutoHideControls({ isPlaying, isFullScreen, containerRef }) {
  const [showControls, setShowControls] = useState(true);

  useEffect(() => {
    // If video isn't playing and we're not in fullscreen,
    // keep the controls always visible:
    if (!isPlaying && !isFullScreen) {
      setShowControls(true);
      return;
    }

    let hideControlsTimeout;
    const container = containerRef.current;
    if (!container) return;

    const resetHideControls = () => {
      // Show controls immediately on interaction:
      setShowControls(true);

      // Clear any previous timeout:
      clearTimeout(hideControlsTimeout);

      // Hide again after 5 seconds of no interaction:
      hideControlsTimeout = setTimeout(() => {
        setShowControls(false);
      }, 5000);
    };

    // Attach events to detect user activity:
    container.addEventListener('mousemove', resetHideControls, { passive: true });
    container.addEventListener('touchstart', resetHideControls, { passive: true });

    // Trigger the first countdown to hide controls:
    resetHideControls();

    // Cleanup on unmount or re-run:
    return () => {
      clearTimeout(hideControlsTimeout);
      container.removeEventListener('mousemove', resetHideControls);
      container.removeEventListener('touchstart', resetHideControls);
    };
  }, [isPlaying, isFullScreen, containerRef]);

  return [showControls, setShowControls];
}

const VideoPlayer = ({
  src = '',
  poster,
  qualityOptions = [],
  thumbnails = [],
  onWatchTimeUpdate,
  customTheme = '',
}) => {
  const videoRef = useRef(null);
  const volumeSliderRef = useRef(null);
  const containerRef = useRef(null);

  // Core states for video playback
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [currentQuality, setCurrentQuality] = useState(null);
  const [isBuffering, setIsBuffering] = useState(false);
  const [skipIndicator, setSkipIndicator] = useState(null);
  const [pauseIndicator, setPauseIndicator] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [currentTime, setCurrentTime] = useState('0:00');
  const [duration, setDuration] = useState('0:00');
  const [isPiP, setIsPiP] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [error, setError] = useState(null);
  const [videoSrc, setVideoSrc] = useState(src);

  // If we are changing quality while playing, we want to resume playback after load
  const [shouldResumePlaying, setShouldResumePlaying] = useState(false);

  /**
   * Automatically hide/show controls based on inactivity and current state.
   */
  const [showControls] = useAutoHideControls({
    isPlaying,
    isFullScreen,
    containerRef,
  });

  const handleVideoEnd = useCallback(() => {
    setIsPlaying(false);
  }, []);

  /**
   * Apply volume/mute changes to the <video> element
   */
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.volume = volume;
      video.muted = isMuted;
    }
  }, [volume, isMuted]);

  /**
   * Detect mobile layout
   */
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  /**
   * Handle setting the default video source or picking from quality options
   */
  useEffect(() => {
    if (qualityOptions.length > 0) {
      const validQualityOptions = qualityOptions.filter((q) => q.src);
      if (validQualityOptions.length === 0) {
        setError('No valid video source available in quality options.');
        setVideoSrc('');
        return;
      }
      setCurrentQuality(validQualityOptions[0]);
      setVideoSrc(validQualityOptions[0].src);
      setError(null);
    } else if (src) {
      setVideoSrc(src);
      setCurrentQuality(null);
      setError(null);
    } else {
      setVideoSrc('');
      setCurrentQuality(null);
      setError('No video source provided.');
    }
  }, [qualityOptions, src]);

  /**
   * Load the video when videoSrc changes
   */
  useEffect(() => {
    const video = videoRef.current;
    if (video && videoSrc) {
      video.src = videoSrc;

      const onLoadedMetadata = () => {
        setDuration(formatTime(video.duration));
        if (shouldResumePlaying) {
          video
            .play()
            .catch((err) => {
              if (err.name === 'AbortError') {
                // Ignore abort errors
                console.log('Play aborted due to a new load request.');
              } else {
                setError('Unable to play the video after source change.');
                console.error(err);
              }
            });
          setShouldResumePlaying(false);
        }
      };
      const onEnded = handleVideoEnd;
      const onErrorEvent = () => {
        let errorMessage = 'An error occurred while playing the video.';
        if (video.error) {
          switch (video.error.code) {
            case video.error.MEDIA_ERR_ABORTED:
              errorMessage = 'Video playback was aborted.';
              break;
            case video.error.MEDIA_ERR_NETWORK:
              errorMessage = 'A network error caused the video download to fail.';
              break;
            case video.error.MEDIA_ERR_DECODE:
              errorMessage =
                'The video was aborted due to corruption or an unsupported feature.';
              break;
            case video.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
              errorMessage =
                'The video could not be loaded because the format is not supported.';
              break;
            default:
              errorMessage = 'An unknown error occurred.';
              break;
          }
        }
        setError(errorMessage);
        console.error('Video Error:', video.error);
      };

      video.addEventListener('loadedmetadata', onLoadedMetadata);
      video.addEventListener('ended', onEnded);
      video.addEventListener('error', onErrorEvent);

      video.load();

      return () => {
        video.removeEventListener('loadedmetadata', onLoadedMetadata);
        video.removeEventListener('ended', onEnded);
        video.removeEventListener('error', onErrorEvent);
      };
    } else if (!videoSrc && !qualityOptions.length && !src) {
      setError('No video source available.');
    }
  }, [videoSrc, qualityOptions, src, handleVideoEnd, shouldResumePlaying]);

  /**
   * Play/Pause handling
   */
  const handlePlayPause = useCallback(() => {
    const video = videoRef.current;
    if (!video) return;

    if (isPlaying) {
      video.pause();
      setPauseIndicator(true);
      setTimeout(() => setPauseIndicator(false), 500);
      setIsPlaying(false);
    } else {
      video
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((err) => {
          if (err.name === 'AbortError') {
            console.log('Play aborted due to a source change.');
          } else {
            setError('Unable to play the video.');
            console.error(err);
          }
        });
    }
  }, [isPlaying]);

  /**
   * Mute/Unmute handling
   */
  const handleMuteToggle = useCallback(() => {
    setIsMuted((prev) => !prev);
  }, []);

  /**
   * Volume slider handling
   */
  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
  };

  /**
   * Fullscreen toggle
   */
  const handleFullScreenToggle = useCallback(async () => {
    const videoContainer = containerRef.current;
    if (!videoContainer) return;

    const isDocumentInFullScreen = () =>
      !!(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      );

    try {
      if (!isDocumentInFullScreen()) {
        // Enter fullscreen
        if (videoContainer.requestFullscreen) {
          await videoContainer.requestFullscreen();
        } else if (videoContainer.webkitRequestFullscreen) {
          await videoContainer.webkitRequestFullscreen();
        } else if (videoContainer.msRequestFullscreen) {
          await videoContainer.msRequestFullscreen();
        }
      } else {
        // Exit fullscreen
        if (document.exitFullscreen) {
          await document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          await document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          await document.msExitFullscreen();
        }
      }
    } catch (err) {
      console.error('Fullscreen toggle failed:', err);
    }
  }, []);

  /**
   * Sync internal state with actual fullscreen changes
   */
  useEffect(() => {
    const handleFullScreenChange = () => {
      const isFull = !!(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      );
      setIsFullScreen(isFull);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('msfullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('msfullscreenchange', handleFullScreenChange);
    };
  }, []);

  /**
   * Progress (scrubbing) changes
   */
  const handleProgressChange = (newTime) => {
    const video = videoRef.current;
    if (!video) return;
    video.currentTime = newTime;
  };

  /**
   * Update currentTime state whenever the video time updates
   */
  const handleTimeUpdate = () => {
    const video = videoRef.current;
    if (!video) return;
    setCurrentTime(formatTime(video.currentTime));
    if (onWatchTimeUpdate) {
      onWatchTimeUpdate(video.currentTime);
    }
  };

  /**
   * Playback rate change
   */
  const handlePlaybackRateChange = (e) => {
    const rate = parseFloat(e.target.value);
    if (videoRef.current) {
      videoRef.current.playbackRate = rate;
    }
    setPlaybackRate(rate);
  };

  /**
   * Quality change
   */
  const handleQualityChange = useCallback(
    (e) => {
      const video = videoRef.current;
      const selectedQuality = qualityOptions.find((q) => q.label === e.target.value);
      if (selectedQuality && selectedQuality.src) {
        const wasPlaying = isPlaying;
        setCurrentQuality(selectedQuality);
        setVideoSrc(selectedQuality.src);
        setError(null);
        if (wasPlaying) {
          setShouldResumePlaying(true);
        }
      }
    },
    [qualityOptions, isPlaying]
  );

  /**
   * Skip forward/back
   */
  const handleSkip = useCallback((seconds) => {
    const video = videoRef.current;
    if (!video) return;
    video.currentTime += seconds;
    setSkipIndicator(seconds > 0 ? `+${seconds}s` : `${seconds}s`);
    setTimeout(() => setSkipIndicator(null), 1000);
  }, []);

  /**
   * Picture-in-Picture toggle
   */
  const handlePictureInPicture = useCallback(() => {
    const video = videoRef.current;
    if (!video) return;
    if (isPiP) {
      document.exitPictureInPicture().catch((error) => {
        console.error('Error exiting Picture-in-Picture mode:', error);
      });
    } else if (video.requestPictureInPicture) {
      video.requestPictureInPicture().catch((error) => {
        console.error('Error entering Picture-in-Picture mode:', error);
      });
    }
    setIsPiP(!isPiP);
  }, [isPiP]);

  /**
   * Mouse wheel for volume
   */
  const handleWheel = useCallback(
    (e) => {
      // Prevent default scroll
      e.preventDefault();
      let newVolume = volume + (e.deltaY < 0 ? 0.05 : -0.05);
      newVolume = Math.min(Math.max(newVolume, 0), 1);
      setVolume(newVolume);
      setIsMuted(newVolume === 0);
    },
    [volume]
  );

  /**
   * Attach wheel listener
   */
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('wheel', handleWheel, { passive: false });
    return () => {
      container.removeEventListener('wheel', handleWheel);
    };
  }, [handleWheel]);

  /**
   * If there's an error and no valid video source, show fallback
   */
  if (error && !videoSrc) {
    return (
      <div className={`video-player-error-fallback ${customTheme}`}>
        <p>{error}</p>
        <p>The video player failed to load, but the rest of the site should still work normally.</p>
      </div>
    );
  }

  const videoClassNames = `video-player ${isFullScreen ? 'fullscreen' : ''} ${
    customTheme ? customTheme : ''
  }`;

  return (
    <div className={videoClassNames} ref={containerRef} tabIndex="0">
      {/* Error message (if any, but there's still some videoSrc) */}
      {error && videoSrc && (
        <div className="error-message">
          {error}
          {/* Fallback retry button only if videoSrc is missing, so might never show here */}
          {!videoSrc && (
            <button
              onClick={() => {
                if (qualityOptions.length > 0) {
                  setVideoSrc(qualityOptions[0].src);
                } else {
                  setVideoSrc(src);
                }
              }}
            >
              Retry
            </button>
          )}
        </div>
      )}

      {/* Actual video element */}
      <video
        ref={videoRef}
        poster={poster}
        onTimeUpdate={handleTimeUpdate}
        onWaiting={() => setIsBuffering(true)}
        onPlaying={() => setIsBuffering(false)}
        controls={false}
        onError={() => setError('An error occurred while playing the video.')}
      />

      {isBuffering && <div className="buffering-indicator">Buffering...</div>}
      {skipIndicator && <div className="skip-indicator">{skipIndicator}</div>}
      {pauseIndicator && <div className="pause-indicator">Paused</div>}

      {/* Main controls (shown/hidden based on showControls) */}
      {showControls && videoSrc && (
        <div
          className="controls-container"
          onClick={(e) => e.stopPropagation()} // Prevent clicks from bubbling up to video container
        >
          <button onClick={handlePlayPause} aria-label={isPlaying ? 'Pause' : 'Play'}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>

          <VolumeControl
            volume={volume}
            isMuted={isMuted}
            onMuteToggle={handleMuteToggle}
            onVolumeChange={handleVolumeChange}
            volumeSliderRef={volumeSliderRef}
          />

          <ProgressBar
            videoRef={videoRef}
            currentTime={currentTime}
            duration={duration}
            thumbnails={thumbnails}
            onProgressChange={handleProgressChange}
          />

          <SkipButton direction="backward" onSkip={() => handleSkip(-10)} />
          <SkipButton direction="forward" onSkip={() => handleSkip(10)} />

          <PiPButton isPiP={isPiP} onTogglePiP={handlePictureInPicture} />

          <FullscreenButton isFullScreen={isFullScreen} onToggleFullScreen={handleFullScreenToggle} />

          <SettingsButton onToggleSettings={() => setShowSettings(!showSettings)} />
          <SettingsMenu
            showSettings={showSettings}
            playbackRate={playbackRate}
            onPlaybackRateChange={handlePlaybackRateChange}
            qualityOptions={qualityOptions}
            currentQuality={currentQuality}
            onQualityChange={handleQualityChange}
          />
        </div>
      )}

      {/* Mobile gesture controls */}
      <GestureControlsMobile
        containerRef={containerRef}
        isMobile={isMobile}
        handlePlayPause={handlePlayPause}
        handleSkip={handleSkip}
        handleFullScreenToggle={handleFullScreenToggle}
        isFullScreen={isFullScreen}
      />

      {/* Desktop quick actions (e.g., double-click skip) */}
      <PCQuickActions
        containerRef={containerRef}
        isMobile={isMobile}
        handlePlayPause={handlePlayPause}
        handleSkip={handleSkip}
        handleFullScreenToggle={handleFullScreenToggle}
      />
    </div>
  );
};

VideoPlayer.propTypes = {
  src: (props, propName, componentName) => {
    if (!props.src && (!props.qualityOptions || props.qualityOptions.length === 0)) {
      return new Error(
        `One of props 'src' or 'qualityOptions' was not specified in '${componentName}'.`
      );
    }
    if (props.src && typeof props.src !== 'string') {
      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}'. Expected a string.`
      );
    }
    return null;
  },
  poster: PropTypes.string,
  qualityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    })
  ),
  thumbnails: PropTypes.arrayOf(PropTypes.string),
  onWatchTimeUpdate: PropTypes.func,
  customTheme: PropTypes.string,
};

export default VideoPlayer;
