import React from 'react';
import { FaCog } from 'react-icons/fa';
import PropTypes from 'prop-types';

const SettingsButton = ({ onToggleSettings }) => {
  return (
    <button onClick={onToggleSettings} aria-label="Settings">
      <FaCog />
    </button>
  );
};

SettingsButton.propTypes = {
  onToggleSettings: PropTypes.func.isRequired,
};

export default SettingsButton;
