// src/components/Post.js
import React, { useEffect, useState } from 'react';
import './Post.css';
import LikeCommentShareButton from './LikeCommentShareButton';
import { Link } from 'react-router-dom';
import { publicApi } from '../services/apiService';
import VideoThumbnails from './VideoThumbnails'; // Only used for 'video' type

const Post = ({ post }) => {
  const [profileImage, setProfileImage] = useState(null);
  const [activeVideoIndices, setActiveVideoIndices] = useState([]);

  const { _id, username, description, mediaTypes, mediaFiles, thumbnails, createdAt } = post || {};

  useEffect(() => {
    if (username) {
      const fetchProfileImage = async () => {
        try {
          const response = await publicApi.get(`/users/${username}`);
          if (response.status === 200) {
            setProfileImage(response.data.profileImage);
          }
        } catch (error) {
          console.error('Error fetching profile image:', error);
        }
      };
      fetchProfileImage();
    }
  }, [username]);

  if (!post) {
    return <div className="post-container">Post not found</div>;
  }

  const handleThumbnailClick = (index) => {
    setActiveVideoIndices((prevIndices) => [...prevIndices, index]);
  };

  const renderMedia = () => {
    if (
      !mediaTypes ||
      !mediaFiles ||
      mediaTypes.length === 0 ||
      mediaFiles.length === 0
    ) {
      return null;
    }

    return (
      <div className="media-container">
        {mediaFiles.map((mediaFile, index) => {
          const type = mediaTypes[index];
          const thumbnailUrl = thumbnails && thumbnails[index] ? thumbnails[index] : null;
          const isActive = activeVideoIndices.includes(index);

          if (type === 'image') {
            return (
              <img
                key={index}
                src={mediaFile}
                alt={`Media ${index + 1}`}
                className="post-image"
                loading="lazy"
              />
            );
          } else if (type === 'video') {
            // For regular videos, we use the thumbnail system unless active
            if (isActive) {
              return (
                <div key={index} className="media-item">
                  <video
                    src={mediaFile}
                    controls
                    className="post-video"
                  >
                    <source src={mediaFile} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              );
            } else {
              const videoData = {
                _id,
                description,
                mediaTypes: [type],
                mediaFiles: [mediaFile],
                thumbnails: thumbnailUrl ? [thumbnailUrl] : [],
              };

              return (
                <div
                  key={index}
                  className="media-item"
                  onClick={() => handleThumbnailClick(index)}
                  role="button"
                  tabIndex={0}
                  aria-label={`Play video ${index + 1}`}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleThumbnailClick(index);
                    }
                  }}
                >
                  <VideoThumbnails
                    video={videoData}
                    onClick={() => handleThumbnailClick(index)}
                  />
                  <div className="play-indicator" aria-hidden="true">▶</div>
                </div>
              );
            }
          } else if (type === 'short') {
            // For short-form videos (like TikTok), skip thumbnails entirely
            // If the user hasn't interacted, just show the auto-playing short
            // (You can adjust this logic as needed, but the request was to not show thumbnails for shorts)
            return (
              <div key={index} className="media-item">
                <video
                  src={mediaFile}
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="post-video"
                >
                  <source src={mediaFile} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  };

  return (
    <div className="post-container">
      <div className="user-info">
        {profileImage ? (
          <img
            src={profileImage}
            alt={`${username}'s profile`}
            className="profile-image"
          />
        ) : (
          <div className="profile-placeholder">No Image</div>
        )}
        <h2 className="username">
          <Link to={`/profile/${username}`}>{username}</Link>
        </h2>
      </div>

      <p className="post-description">{description}</p>

      {renderMedia()}

      <p className="post-time">Posted on: {new Date(createdAt).toLocaleString()}</p>

      <LikeCommentShareButton postId={_id} />
      <Link to={`/post/${_id}`} className="view-post">
        View Post
      </Link>
    </div>
  );
};

export default Post;
