// Header.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; 
import SearchBar from './SearchBar';
import './Header.css';

function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // 1) Encapsulate your "check login" logic in a function
  const checkLoginStatus = () => {
    try {
      const token = localStorage.getItem('accessToken');
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp && decodedToken.exp > currentTime) {
          setIsLoggedIn(true);
          setLoggedInUser({
            userId: decodedToken.userId,
            username: decodedToken.username,
          });
        } else {
          setIsLoggedIn(false);
          setLoggedInUser(null);
        }
      } else {
        setIsLoggedIn(false);
        setLoggedInUser(null);
      }
    } catch (error) {
      console.error('Error checking login status:', error);
      setIsLoggedIn(false);
      setLoggedInUser(null);
    }
  };

  // 2) useEffect to check login on mount & listen for tokenRefreshed
  useEffect(() => {
    // Check immediately on mount
    checkLoginStatus();

    // Function to handle our custom event and re-check the token
    const handleTokenRefreshed = () => {
      console.log('tokenRefreshed event received, re-checking login');
      checkLoginStatus();
    };

    // Add event listener
    window.addEventListener('tokenRefreshed', handleTokenRefreshed);

    // Cleanup
    return () => {
      window.removeEventListener('tokenRefreshed', handleTokenRefreshed);
    };
  }, []);

  // The rest of your menu, unchanged
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  const handleLinkClick = () => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  };

  return (
    <div className="header-container">
      <header className="header">
        <nav className="nav-container">
          {/* Logo and Hamburger Menu */}
          <div className="nav-left">
            <Link to="/" className="logo">Neon Desire</Link>
            <button
              className={`hamburger ${isMobileMenuOpen ? 'active' : ''}`}
              onClick={toggleMobileMenu}
              aria-label="Toggle navigation menu"
            >
              <span className="hamburger-bar"></span>
              <span className="hamburger-bar"></span>
              <span className="hamburger-bar"></span>
            </button>
          </div>

          {/* Navigation Links */}
          <div className={`nav-links-container ${isMobileMenuOpen ? 'open' : ''}`}>
            <div className="nav-links" onClick={handleLinkClick}>
              <Link to="/" className="link-button">
                Home
              </Link>
            </div>
            <div className="category-links">
              <Link to="/videos" className="category-button">Videos</Link>
              <Link to="/shorts" className="category-button">Shorts</Link>
              <Link to="/photos" className="category-button">Photos</Link>
              <Link
                to="/live-streams"
                className="category-button disabled"
                style={{ pointerEvents: 'none', opacity: 0.5 }}
              >
                Live Streams - Coming Soon
              </Link>
            </div>

            <SearchBar />

            <div className="account-section">
              <Link
                to={
                  isLoggedIn && loggedInUser
                    ? `/profile/${loggedInUser.username}`
                    : '/signup'
                }
                className="account-button"
                style={{ textDecoration: 'none' }}
              >
                <i className="fas fa-user account-icon"></i>
                {isLoggedIn ? `Hello, ${loggedInUser.username}` : 'Sign Up / Login'}
              </Link>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
