// src/components/OptionsPost.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reportRoute } from '../services/apiService';
import './OptionsPost.css';

const OptionsPost = ({ postId }) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleOptionsClick = () => {
    setShowOptions((prev) => !prev);
  };

  const handleReport = async () => {
    try {
      const token = localStorage.getItem('token');
      await reportRoute(postId, 'Inappropriate content', token);
      alert('Report submitted successfully.');
      setShowOptions(false);
    } catch (error) {
      console.error('Error reporting post:', error);
    }
  };

  return (
    <div className="button-wrapper">
      <button
        onClick={handleOptionsClick}
        className={`options-button button ${showOptions ? 'active' : ''}`}
        aria-label="Options"
      >
        <i className="fas fa-ellipsis-h"></i>
      </button>
      {showOptions && (
        <div className="options-popup">
          <button onClick={handleReport}>Report</button>
        </div>
      )}
    </div>
  );
};

OptionsPost.propTypes = {
  postId: PropTypes.string.isRequired,
};

export default OptionsPost;