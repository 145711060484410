// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Feed from './components/Feed';
import SignUp from './components/SignUp';
import Login from './components/Login';
import ProfilePage from './components/ProfilePage';
import Videos from './components/Videos';
import Shorts from './components/Shorts';
import Photos from './components/Photos';
import LiveStreams from './components/LiveStreams';
import PostPage from './components/PostPage';
import AboutPage from './components/AboutPage';
import Contact from './components/Contact';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import ModQueuePage from './components/ModQueuePage';
import SearchResultsPage from './components/SearchResultsPage';
import ProhibitedContentPolicy from './components/ProhibitedContentPolicy';
import AgeVerification from './components/AgeVerification';
import './App.css';

// 1) Import the new silentRefresh function (no forced 401):
import { silentRefresh } from './services/apiService'; 

function App() {
  // 2) Call silentRefresh on mount
  useEffect(() => {
    silentRefresh(); 
  }, []);

  return (
    <Router>
      <div className="App">
        <AgeVerification />
        <Header />
        <Routes>
          <Route path="/" element={<Feed />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/profile/:username" element={<ProfilePage />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/shorts" element={<Shorts />} />
          <Route path="/photos" element={<Photos />} />
          <Route path="/live-streams" element={<LiveStreams />} />
          <Route path="/post/:id" element={<PostPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/mod/queue" element={<ModQueuePage />} />
          <Route path="/search" element={<SearchResultsPage />} />
          <Route path="/ProhibitedContentPolicy" element={<ProhibitedContentPolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
