import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const GestureControlsMobile = ({
  containerRef,
  isMobile,
  handlePlayPause,
  handleSkip,
  handleFullScreenToggle,
  isFullScreen
}) => {
  const touchStartTimeRef = useRef(0);
  const doubleTapTimeoutRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container || !isMobile) return;

    const handleTouchStart = (e) => {
      const now = Date.now();
      if (now - touchStartTimeRef.current < 300 && doubleTapTimeoutRef.current) {
        // Double tap detected
        clearTimeout(doubleTapTimeoutRef.current);
        doubleTapTimeoutRef.current = null;

        const touch = e.touches[0];
        const leftBoundary = window.innerWidth / 3;
        const rightBoundary = (2 * window.innerWidth) / 3;

        // Define tapping zones:
        // Left side: skip backwards 10s
        // Right side: skip forwards 10s
        // Middle: toggle fullscreen
        if (touch.clientX < leftBoundary) {
          handleSkip(-10);
        } else if (touch.clientX > rightBoundary) {
          handleSkip(10);
        } else {
          handleFullScreenToggle();
        }
      } else {
        // Single tap start
        touchStartTimeRef.current = now;
        const timeout = setTimeout(() => {
          // Single tap action: pause/play toggle
          handlePlayPause();
          doubleTapTimeoutRef.current = null;
        }, 300);
        doubleTapTimeoutRef.current = timeout;
      }
    };

    container.addEventListener('touchstart', handleTouchStart, { passive: true });

    return () => {
      container.removeEventListener('touchstart', handleTouchStart);
      if (doubleTapTimeoutRef.current) {
        clearTimeout(doubleTapTimeoutRef.current);
      }
    };
  }, [containerRef, isMobile, handlePlayPause, handleSkip, handleFullScreenToggle, isFullScreen]);

  return null;
};

GestureControlsMobile.propTypes = {
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  handlePlayPause: PropTypes.func.isRequired,
  handleSkip: PropTypes.func.isRequired,
  handleFullScreenToggle: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
};

export default GestureControlsMobile;
