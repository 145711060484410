// src/components/SearchResultsPage.js
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { search, api } from '../services/apiService';
import Post from './Post';
import './SearchResultsPage.css';

import SEO from './SEO';
import seoConfig from './seoConfig';

// Import debounce from lodash
import debounce from 'lodash.debounce';

const LIMIT = 10;

const SearchResultsPage = () => {
  // -- Users State --
  const [users, setUsers] = useState([]);
  const [usersPage, setUsersPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);

  // -- Posts State --
  const [posts, setPosts] = useState([]);
  const [postsPage, setPostsPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);

  // -- Loading & Error State --
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [error, setError] = useState(null);

  // -- Cache profile images so we don’t re-fetch them repeatedly --
  const [profileImageCache, setProfileImageCache] = useState({});

  // Get the query from the URL (e.g., /search?query=foo)
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query') || '';

  /**
   * Reset the state whenever the query changes.
   * This ensures we start from page 1 for both users and posts.
   */
  useEffect(() => {
    setUsers([]);
    setUsersPage(1);
    setTotalUsers(0);

    setPosts([]);
    setPostsPage(1);
    setTotalPosts(0);

    setError(null);
  }, [query]);

  /**
   * After resetting, fetch the first page for both users and posts when query changes
   */
  useEffect(() => {
    if (!query.trim()) return;

    // Initial load for both users & posts (page=1)
    fetchUsers(1, true);
    fetchPosts(1, true);
  }, [query]);

  /**
   * Fetch users for a given page. 'isFresh' indicates we’re loading page=1 again.
   */
  const fetchUsers = async (page, isFresh = false) => {
    setLoadingUsers(true);
    try {
      const data = await search({
        query,
        usersPage: page,
        postsPage: 1, // We don't need new posts in this request
        limit: LIMIT,
      });

      if (isFresh) {
        setUsers(data.users);
      } else {
        setUsers((prev) => [...prev, ...data.users]);
      }

      if (typeof data.totalUsers === 'number') {
        setTotalUsers(data.totalUsers);
      }

      // Pre-fetch profile images for the newly fetched users
      await fetchProfileImages(data.users, []);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to fetch users. Please try again.');
    } finally {
      setLoadingUsers(false);
    }
  };

  /**
   * Fetch posts for a given page. 'isFresh' indicates we’re loading page=1 again.
   */
  const fetchPosts = async (page, isFresh = false) => {
    setLoadingPosts(true);
    try {
      const data = await search({
        query,
        usersPage: 1, // We don’t need new users in this request
        postsPage: page,
        limit: LIMIT,
      });

      if (isFresh) {
        setPosts(data.posts);
      } else {
        setPosts((prev) => [...prev, ...data.posts]);
      }

      if (typeof data.totalPosts === 'number') {
        setTotalPosts(data.totalPosts);
      }

      // Pre-fetch profile images for the newly fetched posts
      await fetchProfileImages([], data.posts);
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError('Failed to fetch posts. Please try again.');
    } finally {
      setLoadingPosts(false);
    }
  };

  /**
   * Utility: Fetch profile images for newly fetched users/posts
   */
  const fetchProfileImages = async (newUsers, newPosts) => {
    try {
      const userAndPostNames = [
        ...new Set([
          ...newUsers.map((u) => u.username),
          ...newPosts.map((p) => p.username),
        ]),
      ];

      const promises = userAndPostNames.map(async (username) => {
        if (!profileImageCache[username]) {
          const response = await api.get(`/users/${username}`);
          if (response.status === 200) {
            setProfileImageCache((prev) => ({
              ...prev,
              [username]: response.data.profileImage,
            }));
          }
        }
      });

      await Promise.all(promises);
    } catch (error) {
      console.error('Error fetching profile images:', error);
    }
  };

  /**
   * Handle "Show More" for users
   */
  const handleShowMoreUsers = () => {
    const nextPage = usersPage + 1;
    setUsersPage(nextPage);
    fetchUsers(nextPage);
  };

  /**
   * Handle infinite scroll for posts with debounce
   */
  const handleScroll = useCallback(
    debounce(() => {
      if (loadingPosts) return;

      const nearBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 500;

      // If near bottom, fetch next page of posts (only if we still have more)
      if (nearBottom && posts.length < totalPosts) {
        const nextPage = postsPage + 1;
        setPostsPage(nextPage);
        fetchPosts(nextPage);
      }
    }, 300), // Debounce delay of 300ms
    [loadingPosts, posts, postsPage, totalPosts]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      // Cancel the debounced function on cleanup to prevent memory leaks
      handleScroll.cancel();
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  // SEO data
  const seoData = seoConfig.search(query);

  return (
    <div className="search-page"> {/* Added Wrapper */}
      <div className="search-results">
        <SEO title={seoData.title} description={seoData.description} />

        <h2> "{query}"</h2>

        {/* USERS SECTION */}
        <div className="users-section">
          <h3>Users</h3>
          {users.map((user) => (
            <div key={user._id} className="user-item">
              <div className="user-info">
                {profileImageCache[user.username] ? (
                  <img
                    src={profileImageCache[user.username]}
                    alt={`${user.username}'s profile`}
                    className="profile-image"
                  />
                ) : (
                  <div className="profile-placeholder">No Image</div>
                )}
                <div>
                  <h2 className="username">
                    <Link to={`/profile/${user.username}`}>{user.username}</Link>
                  </h2>
                  <p className="user-bio">{user.bio}</p>
                </div>
              </div>
              <button className="follow-button">Follow</button>
            </div>
          ))}

          {/* Show More Button: Only display if more users exist */}
          {users.length < totalUsers && (
            <button
              onClick={handleShowMoreUsers}
              disabled={loadingUsers}
              style={{ marginTop: '1rem' }}
            >
              {loadingUsers ? 'Loading...' : 'Show More Users'}
            </button>
          )}
        </div>

        {/* POSTS SECTION */}
        <div className="posts-section">
          <h3>Posts</h3>
          {posts.map((post) => (
            <Post key={post._id} post={post} />
          ))}

          {loadingPosts && <div>Loading posts...</div>}
        </div>

        {/* Error Handling */}
        {error && (
          <div className="error-message">
            <p>Error: {error}</p>
            <button onClick={() => window.location.reload()}>Retry</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResultsPage;
