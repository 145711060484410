import React from 'react';
import PropTypes from 'prop-types';

const PiPButton = ({ isPiP, onTogglePiP }) => {
  return (
    <button onClick={onTogglePiP} aria-label="Toggle Picture-in-Picture">
      {isPiP ? 'Exit PiP' : 'PiP'}
    </button>
  );
};

PiPButton.propTypes = {
  isPiP: PropTypes.bool.isRequired,
  onTogglePiP: PropTypes.func.isRequired,
};

export default PiPButton;
