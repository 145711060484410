import React from 'react';
import { FaStepForward, FaStepBackward } from 'react-icons/fa';
import PropTypes from 'prop-types';

const SkipButton = ({ direction, onSkip }) => {
  const skipAmount = direction === 'forward' ? 10 : -10;
  const Icon = direction === 'forward' ? FaStepForward : FaStepBackward;
  const label = direction === 'forward' ? 'Skip Forward 10 Seconds' : 'Skip Backward 10 Seconds';

  return (
    <button onClick={() => onSkip(skipAmount)} aria-label={label}>
      <Icon /> 10s
    </button>
  );
};

SkipButton.propTypes = {
  direction: PropTypes.oneOf(['forward', 'backward']).isRequired,
  onSkip: PropTypes.func.isRequired,
};

export default SkipButton;
