// src/components/Ads.js
import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

// Flags to ensure each script is loaded only once
let isBiScriptLoaded = false;
let isOutstreamScriptLoaded = false;
let isPopunderScriptLoaded = false;
let isMasterScriptLoaded = false; // New flag for master ad

const Ads = forwardRef(({
  type,
  spot,
  containerId,
  cookieExpires,
  width,
  height,
  extid,
  nativeSettings, // New prop for master ad settings
}, ref) => {
  const adContainerRef = useRef(null);

  useEffect(() => {
    const loadScript = (src, id, attributes = {}) => {
      return new Promise((resolve, reject) => {
        if (document.getElementById(id)) {
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.src = src;
        script.id = id;
        Object.keys(attributes).forEach((key) => {
          script.setAttribute(key, attributes[key]);
        });
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        adContainerRef.current.appendChild(script);
      });
    };

    const handleAd = async () => {
      try {
        if (type === 'outstream') {
          if (!isOutstreamScriptLoaded) {
            await loadScript('//cdn.tsyndicate.com/sdk/v1/outstream.video.js', 'ts-outstream-video-script');
            isOutstreamScriptLoaded = true;
          }
          if (window.TSOutstreamVideo) {
            window.TSOutstreamVideo({
              spot,
              containerId,
              cookieExpires,
            });
          }
        } else if (type === 'banner') {
          if (!isBiScriptLoaded) {
            await loadScript('//cdn.tsyndicate.com/sdk/v1/bi.js', 'ts-banner-script', {
              'data-ts-spot': spot,
              'data-ts-width': width,
              'data-ts-height': height,
              'data-ts-extid': extid,
            });
            isBiScriptLoaded = true; // Ensure script is loaded only once
          }
          // The iframe is already rendered; no additional handling needed
        } else if (type === 'popUnder') {
          if (!isPopunderScriptLoaded) {
            await loadScript('//cdn.tsyndicate.com/sdk/v1/p.js', 'ts-popunder-script', {
              'data-ts-spot': spot,
            });
            isPopunderScriptLoaded = true;
          }
        } else if (type === 'master') { // New master ad type handling
          if (!isMasterScriptLoaded) {
            await loadScript('//cdn.tsyndicate.com/sdk/v1/master.spot.js', 'ts-master-spot-script');
            isMasterScriptLoaded = true;
          }
          if (window.TsMasterSpot && nativeSettings) {
            window.TsMasterSpot({
              containerId,
              spot,
              nativeSettings,
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    handleAd();

    return () => {
      if (type === 'outstream') {
        const container = document.getElementById(containerId);
        if (container) {
          container.innerHTML = '';
        }
      }
      // You can add cleanup for other types if necessary
    };
  }, [type, spot, containerId, cookieExpires, width, height, extid, nativeSettings]);

  // Expose the triggerPopUnder function to parent components
  useImperativeHandle(ref, () => ({
    triggerPopUnder: () => {
      if (type === 'popUnder') {
        const redirectUrl = `//tsyndicate.com/api/v1/direct/${spot}?`;
        const newWindow = window.open(redirectUrl, '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.focus();
        } else {
          console.warn('Pop-under was blocked by the browser.');
        }
      }
    },
  }));

  const renderAd = () => {
    if (type === 'outstream') {
      return <div id={containerId} ref={adContainerRef}></div>;
    } else if (type === 'banner') {
      return (
        <iframe
          width={width}
          height={height}
          frameBorder="0"
          scrolling="no"
          src={`//tsyndicate.com/iframes2/${spot}.html?extid=${extid}`}
          title="Banner Advertisement"
          ref={adContainerRef}
          loading="lazy"
        ></iframe>
      );
    } else if (type === 'master') { // Render for master ad type
      return <div id={containerId} ref={adContainerRef}></div>;
    }
    // No rendering needed for popUnder as it's triggered via method
    return null;
  };

  return <>{renderAd()}</>;
});

Ads.propTypes = {
  type: PropTypes.oneOf(['outstream', 'banner', 'popUnder', 'master']).isRequired, // Added 'master'
  spot: PropTypes.string.isRequired,
  containerId: PropTypes.string, // Required for outstream and master ads
  cookieExpires: PropTypes.string, // Optional for outstream ads
  width: PropTypes.number, // Required for banner ads
  height: PropTypes.number, // Required for banner ads
  extid: PropTypes.string, // Required for banner ads
  nativeSettings: PropTypes.shape({ // New prop for master ad settings
    cols: PropTypes.number,
    rows: PropTypes.number,
    titlePosition: PropTypes.string,
    adsByPosition: PropTypes.string,
    type: PropTypes.string,
    styles: PropTypes.shape({
      container: PropTypes.object,
      thumb: PropTypes.object,
      label: PropTypes.object,
      headlineLink: PropTypes.object,
      brandnameLink: PropTypes.object,
    }),
  }),
};

Ads.defaultProps = {
  cookieExpires: '4',
  width: 300,
  height: 250,
  nativeSettings: { // Default nativeSettings for master ad
    cols: 5,
    rows: 1,
    titlePosition: 'none',
    adsByPosition: 'none',
    type: 'label-under',
    styles: {
      container: {
        width: '100%',
      },
      thumb: {
        'border-radius': '4px',
      },
      label: {
        height: '80px',
        background: 'rgba(255,255,255,0.65)',
      },
      headlineLink: {
        'padding-top': '5px',
        'font-size': '12px',
        'font-weight': 'bold',
        'min-height': '45px',
      },
      brandnameLink: {
        color: '#444',
      },
    },
  },
};

export default Ads;
